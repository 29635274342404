<template>
  <div class="w-100 d-flex flex-column">
    <base-tool-bar
      title="Informations"
      subtitle="Informations générales"
    ></base-tool-bar>
    <base-form class="mr-md-3" :loading="loading" @click="updateClient" shadow>
      <base-form-group title="Client" dense icon="briefcase">
        <base-form-row row>
          <BaseInputArea
            v-model="client_adresse"
            text="Adresse"
            :errors="feedback.client_adresse"
          ></BaseInputArea>
        </base-form-row>
        <base-form-row row>
          <base-input
            v-model="client_mail1"
            inputText="Email 1"
            :errors="feedback.client_mail1"
          ></base-input>
          <base-input
            v-model="client_mail2"
            inputText="Email 2"
            :errors="feedback.client_mail2"
          ></base-input>
        </base-form-row>
        <base-form-row row>
          <base-input
            v-model="client_tel1"
            inputText="Tel 1"
            :errors="feedback.client_tel1"
          ></base-input>
          <base-input
            v-model="client_tel2"
            inputText="Tel 2"
            :errors="feedback.client_tel2"
          ></base-input>
        </base-form-row>
        <base-form-row row></base-form-row>
        <base-form-row row>
          <base-input
            v-model="client_tel3"
            inputText="Tel 3"
            :errors="feedback.client_tel3"
          ></base-input>
          <base-input
            v-model="client_tel_fax"
            inputText="Fax"
            :errors="feedback.client_tel_fax"
          ></base-input>
        </base-form-row>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";

export default {
  components: {
    BaseInput,
    BaseInputArea,
    BaseForm,
    BaseFormGroup,
    BaseFormRow,
    BaseToolBar
  },
  data() {
    return {
      loading: false,
      lists: {},
      feedback: {}
    };
  },
  props: {
    data: {
      Type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      update: "clients/update"
    }),
    updateClient: function() {
      this.loading = true;
      this.feedback = {};
      this.update()
        .catch(error => this.errHandler(error))
        .finally(() => (this.loading = false));
    },
    errHandler: function(error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    selectCompte: function(compte) {
      this.$store.commit("clients/setCompte", compte);
      this.$refs.comptesmanagerselect.hideModal();
    },
    selectClient: function(client) {
      this.$store.commit("clients/setClient", client);
      this.$refs.clientsmanagerselect.hideModal();
    }
  },
  computed: {
    ...mapFields("clients", [
      "client.client_adresse",
      "client.client_mail1",
      "client.client_mail2",
      "client.client_mail3",
      "client.client_tel1",
      "client.client_tel2",
      "client.client_tel3",
      "client.client_tel_fax"
    ]),
    compteDescription() {
      if (this.client_comptes) {
        return this.client_comptes.description;
      }
      return "";
    }
  }
};
</script>
